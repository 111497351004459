<template>
  <v-card class="mb-3 host-dashboard">
    <v-card-text
        v-if="selectedHost && hostStats"
        class="host-stats-component d-flex align-center justify-space-between flex-wrap"
    >
      <div class="host-account-id d-flex align-center">
        <span>Account Id: {{ selectedHost.accountId }}</span>
        <v-btn
            class="copy-account-icon"
            icon
            small
            @click="copyAccountId"
        >
          <v-icon small>
            mdi-content-copy
          </v-icon>
        </v-btn>
      </div>
        <div v-if="selectedHost.status !== HOST_STATUS_DRAFT">
        <span class="host-admins text-start stat-text-style">
          Host Admins: <span
            v-for="(user, index) of hostStats.admins"
            :key="user.id"
          >
            <router-link class="admin-name-link" :to="`/profiles/${user.id}`">{{ user.fullName }}</router-link><span>{{ index !== hostStats.admins.length - 1 ? ", " : "" }}</span>
          </span>
        </span>
      </div>
      <div>
        <span class="total-size text-start stat-text-style">Total size of files: {{ totalSizeOfFiles }}</span>
      </div>
      <div>
        <span class="last-updated text-start stat-text-style">Last updated: {{ lastUpdated }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { dateTimeMomentFormat, copyText, HOST_STATUS_DRAFT } from "../../utils/utils";

export default {
  name: "HostStats",

  data: () => ({
    HOST_STATUS_DRAFT,
  }),

  computed: {
    ...mapState("hostModule", ["selectedHost", "hostStats"]),

    totalSizeOfFiles() {
      return this.hostStats
        ? this.hostStats.totalSizeOfFiles.value
        : 0;
    },

    lastUpdated() {
      return this.hostStats
        ? moment(this.hostStats.lastUpdatedTime)
          .format(dateTimeMomentFormat)
        : "";
    },
  },

  methods: {
    copyAccountId() {
      copyText(this.selectedHost.accountId);
    },
  },
};
</script>

<style lang="scss">
.host-dashboard {
  .admin-name-link {
    color: var(--v-primary-base) !important;
    text-decoration: underline !important;
  }

  .stat-text-style {
    font-size: 14px;
  }
}
</style>
